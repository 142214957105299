import React from 'react';
import {useCart} from '@shopify/hydrogen-react';

const CartDetails = ({ lines,cost }) => {
  const cart = useCart();
  
  const { linesRemove } = cart;

  const handleRemove = (lineId) => {
    // Find the line with the given lineId
    const lineToRemove = lines.find(line => line.id === lineId);
  
    // Find the _io_order_group value of the line
    const orderGroupAttribute = lineToRemove.attributes.find(attribute => attribute.key === '_io_order_group');
    const orderGroupValue = orderGroupAttribute ? orderGroupAttribute.value : null;
  
    // If orderGroupValue exists, find all lines that have _io_parent_order_group value equal to the orderGroupValue
    let relatedLineIds = [lineId];
    if (orderGroupValue) {
      const relatedLines = lines.filter(line => {
        const parentOrderGroupAttribute = line.attributes.find(attribute => attribute.key === '_io_parent_order_group');
        return parentOrderGroupAttribute && parentOrderGroupAttribute.value === orderGroupValue;
      });
  
      // Get the ids of all related lines, including the initially selected line
      relatedLineIds = [...relatedLineIds, ...relatedLines.map(line => line.id)];
    }
  
    // Remove all related lines
    linesRemove(relatedLineIds);
  }


  React.useEffect(() => {
    console.log('CartDetails - cost changed:', cost);
  }, [cost]);


  return (
    <div>
      {lines.map((line, index) => {
        // Check if the line has an attribute with key '_io_parent_order_group'
        const hasParentOrderGroup = line.attributes.some(attribute => attribute.key === '_io_parent_order_group');

        // If the line has the attribute, do not render it
        if (hasParentOrderGroup) {
          return (
            <div key={index}>
            
              <h4>{line.quantity}X {line.merchandise.title}, {line.cost.totalAmount.amount} {line.cost.totalAmount.currencyCode}</h4>
              {line.attributes.map((attribute, attrIndex) => (
              <p key={attrIndex}>Attribute: {attribute.key} - {attribute.value}</p>
            ))}
            </div>
          );
        }

        return (
          <div key={index}>
            <h3>{line.merchandise.product.title}</h3>
            <p>Quantity: {line.quantity}</p>
            <p>Total Amount: {line.cost.totalAmount.amount} {line.cost.totalAmount.currencyCode}</p>
            
            <p>Product Variant: {line.merchandise.title}</p>
            <p>Product Price: {line.merchandise.price.amount} {line.merchandise.price.currencyCode}</p>
            {line.attributes.map((attribute, attrIndex) => (
              <p key={attrIndex}>Attribute: {attribute.key} - {attribute.value}</p>
            ))}
            <button onClick={() => handleRemove(line.id)}>Remove from cart</button>
          </div>
        );
      })}
      <div>
        <h3>Subtotal: {cost.totalAmount.amount} {cost.totalAmount.currencyCode}</h3>
      </div>
    </div>
  );


};

export default CartDetails;