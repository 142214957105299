import { useState, useEffect } from 'react';

import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { Container} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser, faPause, faTimes } from '@fortawesome/free-solid-svg-icons';



const StyledButton = styled.button`
    max-width: 75%;
    margin: 15px;
    font-size: 1em;
    font-weight: 500;
    padding: 15px 15px;
    color: white;
    background: linear-gradient(to right, #007BFF, #0096FF);
    border: none;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.3s ease-out;
    position: relative;
    overflow: hidden;
    
    &:hover {
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(4px);
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;


const SoftphoneContainer = styled(Container)`
  height: 400px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333; // dark gray
  border-radius: 5%;
`;

const SoftphoneCard = styled.div`
  background: #333333;
  height: calc(400px - 100px); // subtract the height of the header and footer
  width: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: white; // white text
  border-radius: 5%; 
`;

const Header = styled.header`
  font-size: calc(5px + 1vmin);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 50px;
  background-color: #333333;
  text-align: center;
  color: white; // white text
`;

const Footer = styled.footer`
  width: 100%;
  height: 50px;
  background-color: #333333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
`;

// Add styling for the Footer buttons
const FooterButton = styled.button`
  background-color: ${props => props.color || 'transparent'};
  border-color: ${props => props.color || 'none'};
  border: ${props => props.border || 'none'};
  color: white; // white text
  width: 60px;
  height: 60px;
  border-radius: 50%; // circle shape
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; // larger icon
`;


const CallerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: white; // white text
`;


const CallerNumber = styled.p`
  font-size: calc(10px + 1vmin);
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PersonIcon = styled(FontAwesomeIcon)`
  padding: 15px;
  border-radius: 50%; // circle shape
  color: #333333;
  background-color: white;
  font-size: 50px;
 
`;



const ErrorContainer = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
`;


const StyledInput = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: ${props => props.inputColor || "black"};
  background: papayawhip;
  border: none;
  border-radius: 3px;
`;





const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const StyledRadioButton = styled.label`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
`;

const StyledRadioInput = styled.input`
  margin-right: 10px;
`;


const Reminder = styled.div`
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  background-color: #f8d7da; // light pink
  color: #721c24; // dark red
  font-size: 0.6em;
  line-height: 1.5;
`;

const ReminderList = styled.ul`
  list-style-type: none;
  text-align: center;
`;

// Then, in your component's return statement, add the

function OrderToAudio({ socket, comparisonResults, recivedComparisonResults, setRecivedComparisonResults,sessionId}) {
  // const [orderId, setOrderId] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [audioPlayer, setAudioPlayer] = useState();
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [getCallButtonClicked, setGetCallButtonClicked] = useState(false);
  const [showSoftphone, setShowSoftphone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [orderId, setOrderId] = useState(null);




  useEffect(() => {
    setGetCallButtonClicked(false);
    if(socket.current) {
      const currentSocket = socket.current;
      // Listen for order data over the socket
      currentSocket.on('orderData', (data) => {
        
        // Handle incoming order data
        const parsedData = JSON.parse(data);
        // const simplifiedOrderData = simplifyingOrderData(parsedData);
        setOrderData(parsedData);
          // Log the received data to the console
        console.log('parsedData',parsedData);
      });

      // Listen for audio data over the socket
      currentSocket.on('audioData', (base64Audio) => {
        setIsLoadingAudio(false);
        // Convert base64 audio data to a data URL
        const audioSrc = 'data:audio/mpeg;base64,' + base64Audio;

        // Create an audio element
        const audio = new Audio(audioSrc);

        // Set the audioPlayer state
        setAudioPlayer(audio);
        setAudioPlayed(false);  // Reset audioPlayed when new audio is received
        setShowSoftphone(true);
        setRecivedComparisonResults(false);
      });

      currentSocket.on('orderDataError', (errorMsg) => {
        setErrorMessage(errorMsg);
      });


      // Cleanup function
      return () => {
        // Remove the listener when the component unmounts
        currentSocket.off('orderData');
        currentSocket.off('audioData');
        currentSocket.off('orderDataError'); 
      };
    }
  }, [socket,recivedComparisonResults, setRecivedComparisonResults]); // Rerun this effect whenever the socket changes



  const [model, setModel] = useState('small');
  const [hasAudio, setHasAudio] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    setGetCallButtonClicked(true);
    setIsLoadingAudio(true);
    // Send the order ID over the socket
    
    // Send the order ID and customer ID over the socket
    socket.current.emit('message', JSON.stringify({ 
      type: 'get_training_audio', 
      customerId: comparisonResults[0].customerId, 
      orderId: orderId, 
      model: model,
      hasAudio: hasAudio,
      sessionId:sessionId
    }));
  };

  const handlePlayAudio = () => {
    if (audioPlayer) {
      audioPlayer.play();
      if (!audioPlayed) {
        setAudioPlayed(true);
      }
    }
  }

  const handleEndCall = () => {
    setShowSoftphone(false);
  };

  const handlePauseAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
    }
  }

  
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        {isLoadingAudio ? (
          <div>
            {isLoadingAudio && <ClipLoader color={"#123abc"} loading={isLoadingAudio} css={override} size={150} />}
            <p>Creating a Training Call...</p>
          </div>
        ) : (
          !getCallButtonClicked && recivedComparisonResults && !showSoftphone && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <StyledButton type="submit" className="btn btn-primary">
                <span style={{fontWeight: "bold", fontSize: "1em", color: "#fff"}}>Receive A Training Customer Call</span>
              </StyledButton>

              {comparisonResults[0].customerId === "6410860953774" && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <StyledInput
                    type="text"
                    placeholder="Enter Order ID"
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <StyledFieldset>
                      <legend>Model</legend>
                      <StyledRadioGroup>
                        <StyledRadioButton>
                          <StyledRadioInput
                            type="radio"
                            value="large"
                            checked={model === 'large'}
                            onChange={() => setModel('large')}
                          />
                          Large
                        </StyledRadioButton>
                        <StyledRadioButton>
                          <StyledRadioInput
                            type="radio"
                            value="small"
                            checked={model === 'small'}
                            onChange={() => setModel('small')}
                          />
                          Small
                        </StyledRadioButton>
                      </StyledRadioGroup>
                    </StyledFieldset>
                    <StyledFieldset>
                      <legend>Has Audio</legend>
                      <StyledRadioGroup>
                        <StyledRadioButton>
                          <StyledRadioInput
                            type="radio"
                            value="Yes"
                            checked={hasAudio === true}
                            onChange={() => setHasAudio(true)}
                          />
                          Yes
                        </StyledRadioButton>
                        <StyledRadioButton>
                          <StyledRadioInput
                            type="radio"
                            value="No"
                            checked={hasAudio === false}
                            onChange={() => setHasAudio(false)}
                          />
                          No
                        </StyledRadioButton>
                      </StyledRadioGroup>
                    </StyledFieldset>
                  </div>

                  <StyledButton type="submit" className="btn btn-primary">
                    <span style={{fontWeight: "bold", fontSize: "1em", color: "#fff"}}>Receive A Call By orderId</span>
                  </StyledButton>
                </div>
              )}
            </div>
          )
        )}
        {errorMessage && (
          <ErrorContainer>
            <p>Error: {errorMessage}</p>
          </ErrorContainer>
        )}
      </form>
      {showSoftphone && orderData && audioPlayer &&( 
       <> 
      <SoftphoneContainer>      
        <SoftphoneCard>     
            <Header>
                <p>Incoming Call</p>
            </Header>
          <CallerInfo>
            <PersonIcon icon={faUser} />
            <CallerNumber>{orderData.shipping_address.last_name}</CallerNumber>
          </CallerInfo>
          <Footer>
            <FooterButton color="red" onClick={handleEndCall}><FontAwesomeIcon icon={faTimes} /></FooterButton>
            <FooterButton border="1px solid white" onClick={handlePauseAudio}><FontAwesomeIcon icon={faPause} /></FooterButton>
            <FooterButton color="green" onClick={handlePlayAudio}><FontAwesomeIcon icon={faPhone} /></FooterButton>
          </Footer>
        </SoftphoneCard>

      </SoftphoneContainer>
      <Reminder>
        Hey there, just a few things to remember:
        <ReminderList>
          <li>If you make any mistakes during the order, we're here to help! We'll provide guidance on Skype. Just do your best, you're doing great!</li>
          <li>If an order takes over 6 minutes to complete, we'll have to mark it as skipped. But don't worry, it happens to the best of us!</li>
          <li>Skipped orders, unless they're due to technical issues, will be scored as 0. But remember, every mistake is a learning opportunity!</li>   
        </ReminderList>
      </Reminder>
      </>
      )}
    </>
  );
}

export default OrderToAudio;
