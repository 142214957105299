import React, { useState, useEffect, useRef, useCallback } from 'react';
// import {Component} from 'react';
import ScoreChecker from './scoreChecker.js';

import { useLocation } from 'react-router-dom';


import { Routes, Route } from "react-router-dom";

import axios from 'axios';
import logo from './logo.svg';
import './App.css';
import io from 'socket.io-client';
import  OrderTable  from './LineItemsTable.js';
import styled from 'styled-components';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import OrderToAudio from './OrderToAudio.js';

import html2canvas from 'html2canvas';

// import TwilioPhone from './TwilioPhone'; 

import AudioMixer from './AudioMixer';

import POSComponent from './HeadlessPOS/POSComponent';


import {ShopifyProvider} from '@shopify/hydrogen-react';
import {CartProvider} from '@shopify/hydrogen-react';


// class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, info) {
//     // Log error to monitoring service
//     console.log(error, info);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render a fallback UI here
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }





const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const CoolBorder = styled.div`
  border: ${props => props.$border ? '1px solid red' : '1px solid #e0e0e0'}; /* A light border for that subtle charm */
  padding: 5px; /* Some breathing room */
  background-color: #fafafa; /* A background that's easy on the eyes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* A touch of shadow for depth */
  background-color: rgba(255, 255, 255, 0.2); /* 50% transparency, 100% awesomeness */
  border-radius: 15px;
  margin: 10px;
`;

const OrderTableStyled = styled.div`
  max-width: 49%; // This allows for 2 components side by side with a 10px gap in between
  width: 100%;
  margin: 5px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
const StyledButton = styled.button`
    line-height: 1.5em;
    max-width: 75%;
    margin: 5px;
    font-size: 0.6em;
    font-weight: 500;
    padding: 10px;
    color: white;
    background: linear-gradient(to right, #007BFF, #0096FF);
    border: none;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.3s ease-out;
    position: relative;
    overflow: hidden;
    
    &:hover {
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(4px);
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const ScoreContainer = styled.div`
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2); /* Consistent with CoolBorder */
`;

const TranscriptContainer = styled.div`
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2); /* Consistent with CoolBorder */

  & > p {
    margin: 5px;
    font-weight: bold; 
    font-size: 15px;
  }
`;



const ScoreHeading = styled.h3`
  color: #007BFF; /* Picking up the blue from your gradient button */
  font-weight: 600;
  margin: 5px;
`;

const ScoreDetail = styled.p`
  color: #333; /* A darker text for contrast */
  font-size: 0.8em; /* Slightly smaller than the heading */
  margin: 2px 0; /* A little space between lines */
`;


const ReviewRequest = styled.p`
  font-size: 2em;
  font-weight: bold;
`;




function App() {

  console.log("App() has run");
  const location = useLocation();
  const [comparisonResults, setComparisonResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [recivedComparisonResults, setRecivedComparisonResults] = useState(false);
  const [requestStatuses, setRequestStatuses] = useState([]);
  const [copyStatusMessage, updateCopyStatus] = useState('📸 Copy to Skype');
  const [requireReview, setRequireReview] = useState(Array(comparisonResults.length).fill(false));
  const [isAudioMixer, setIsAudioMixer] = useState(false);

  const [sessionId, setSessionId] = useState(null);


  const simplifyingOrderData = (order, notMatchingData) => {
    if (!order) {
      console.log(`no data for rawOrder`);
      return { order: [], notMatchingData: [] };
    }
  

    const simplifiedOrder = {
      id: Number(order.legacyResourceId),
      name: order.name,
      created_at: order.createdAt,
      note: order.note,
      customer: {
        id: Number(order.customer.legacyResourceId),
        first_name: order.customer.firstName,
        default_address:{
          address1: order.customer.defaultAddress.address1,
          address2: order.customer.defaultAddress.address2,
          city: order.customer.defaultAddress.city,
          province_code: order.customer.defaultAddress.provinceCode,
          zip: order.customer.defaultAddress.zip,
          phone: order.customer.defaultAddress.phone
        }
      },
      shipping_address: order.shippingAddress 
      ? { last_name: order.shippingAddress.lastName } 
      : {},
      line_items: order.lineItems.edges.map(edge => {
        const isNotMatching = notMatchingData.some(item => item.node.id === edge.node.id);

        return {
          price: edge.node.originalUnitPriceSet.shopMoney.amount,
          quantity: edge.node.quantity,
          properties: edge.node.customAttributes.map(attribute => ({ name: attribute.key, value: attribute.value })),
          title: edge.node.title,
          variant_title: edge.node.variant ? (edge.node.variant.title === 'Default Title' ? null : edge.node.variant.title) : null,
          sku: edge.node.variant ? edge.node.variant.sku : null,
          isNotMatching: isNotMatching
        };
      }),
      subtotal_price: order.subtotalPriceSet.shopMoney.amount,
      total_tax: order.totalTaxSet.shopMoney.amount,
      total_price: order.totalPriceSet.shopMoney.amount,
      metafield: order.metafield
    };

    return { simplifiedOrder, notMatchingData };

  }  

  function sortLineItems(order) {
    if (!order || !order.line_items) {
      console.log("order.line_items is undefined");
      return order;
    }
    let sortedItems = [];
    let parentItems = {};
    let childItems = {};
  
    order.line_items.forEach(item => {
      let orderGroup = item.properties.find(prop => prop.name === '_io_order_group')?.value;
      let parentGroup = item.properties.find(prop => prop.name === '_io_parent_order_group')?.value;
  
      if (orderGroup && parentGroup) {
        throw new Error("Line item cannot have both _io_order_group and _io_parent_order_group");
      }
  
      if (orderGroup) {
        // Check if there are any child items waiting for this parent
        if (childItems[orderGroup]) {
          sortedItems.push(item, ...childItems[orderGroup]);
          delete childItems[orderGroup];
        } else {
          sortedItems.push(item);
        }
        parentItems[orderGroup] = item;
      } else if (parentGroup) {
        // Check if the parent item has already been added to sortedItems
        if (parentItems[parentGroup]) {
          // Insert this child item right after its parent
          let parentIndex = sortedItems.indexOf(parentItems[parentGroup]);
          sortedItems.splice(parentIndex + 1, 0, item);
        } else {
          // The parent item has not been encountered yet, so we add this to childItems
          if (!childItems[parentGroup]) childItems[parentGroup] = [];
          childItems[parentGroup].push(item);
        }
      } else {
        sortedItems.push(item);
      }
    });
  
    // Check if there are any remaining childItems that weren't included
    for (let group in childItems) {
      sortedItems.push(...childItems[group]);
    }
  
    order.line_items = sortedItems;
    return order;
  }
  
  const socket = useRef(null);


  useEffect(() => {
    setRequestStatuses(Array(comparisonResults.length).fill('idle'));
  }, [comparisonResults]);  




  useEffect(() => {
    // console.log('useEffect - location', location);



    // Extract session_id from URL
    const params = new URLSearchParams(location.search);
    setSessionId(params.get('session_id'));
  }, [location]);
  
  
  useEffect(() => {

        // Initialize socket inside useEffect
      socket.current = io('https://api.pacificpearltraining.site/comparison');
      console.log('sessionId', sessionId, typeof sessionId);
    
    // Send session_id to backend for verification
    if (sessionId) {
      axios.get('https://api.pacificpearltraining.site/verify-session', {
        params: {
          session_id: sessionId
        }
      })
      .then(response => {
        // Handle response
        if (response.data.success) {
          console.log('Session ID is valid');
          // Store session ID in local storage
          localStorage.setItem('sessionId', sessionId);
          


          socket.current.on('connect', () => {
            setIsSocketConnected(true);
            socket.current.emit('message', JSON.stringify({ type: 'session_id', value: sessionId, sessionId:sessionId }));
          });


          socket.current.on('connect_error', (error) => {
            console.error('Connection Error:', error);
          });
      
          socket.current.on('connect_timeout', () => {
            console.error('Connection Timeout');
          });

          socket.current.on('comparisonResult', (event) => {
            // Handle incoming comparison result
            const parsedEvent = JSON.parse(event);
            const processedOrder1 = simplifyingOrderData(parsedEvent.order1Data, parsedEvent.notMatchingInOrder1Data);
            const processedOrder2 = simplifyingOrderData(parsedEvent.order2Data, parsedEvent.notMatchingInOrder2Data);
            // console.log('processedOrder1',processedOrder1);
            // console.log('processedOrder2',processedOrder2);
            console.log('parsedEvent.GPTCompletionData',parsedEvent.GPTCompletionData);
            const sortedOrder1 = sortLineItems(processedOrder1.simplifiedOrder);
            const sortedOrder2 = sortLineItems(processedOrder2.simplifiedOrder);

            const processedComparisonResult = {
              order1Data: sortedOrder1,
              order2Data: sortedOrder2,
              notMatchingInOrder1Data: processedOrder1.notMatchingData,
              notMatchingInOrder2Data: processedOrder2.notMatchingData,
              customerId: parsedEvent.customerId,
              customerTags: parsedEvent.customerTags,
              score: parsedEvent.score,
              unmatchedPenalty: parsedEvent.unmatchedPenalty,
              timePenalty: parsedEvent.timePenalty,
              GPTCompletionData:parsedEvent.GPTCompletionData
            };
          

            setComparisonResults(prevResults => {
              // Get the createdAt date from the new result
              const newResultDate = new Date(processedComparisonResult.order1Data.created_at);
            
              // Get the createdAt date from the last result
              const lastResultDate = prevResults.length > 0 ? new Date(prevResults[0].order1Data.created_at) : new Date(0);
            
              // If the new result is more recent, insert it at the beginning
              if (newResultDate > lastResultDate) {
                console.log("add processedComparisonResult to the beginning of ComparisonResults:",[processedComparisonResult, ...prevResults]);
                return [processedComparisonResult, ...prevResults];
              } else {
                // Else, append it at the end
                console.log("add processedComparisonResult to the end of ComparisonResults:",[...prevResults, processedComparisonResult]);
                return [...prevResults, processedComparisonResult];
              }

            });

            setLoading(false);
            console.log('recived compareisonResult:');
            console.dir(parsedEvent,{ depth: null });
            setRecivedComparisonResults(true);
          });

          socket.current.on('error', (error) => {
            console.error('Socket.IO error:', error);
          });

          

          return () => {
            console.log('Close Socket.IO connection when component unmounts');
            socket.current.disconnect();
          };




        } else {
          console.log('Invalid session ID');
        }
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
    }

    // Cleanup function
    return () => {
      if (socket.current) {
          // Disconnect from the socket
          socket.current.disconnect();

          // Set the current reference to null
          socket.current = null;
      }
    }
  }, [sessionId]);

  const loadMoreOrders = useCallback(() => {
    setLoading(true); // Start loading when the request begins.
    const lastOrderId = Number(comparisonResults[comparisonResults.length - 1].order1Data.id);
    const lastOrderData = {
      type: 'next_order', 
      lastOrderId: lastOrderId,
      customerId: comparisonResults[comparisonResults.length - 1].customerId,
      customerTags: comparisonResults[comparisonResults.length - 1].customerTags,
      sessionId: sessionId
    }
    console.log("loadMoreOrders Button - lastOrderData",lastOrderData);
    socket.current.emit('message', JSON.stringify(lastOrderData));
  }, [comparisonResults,sessionId]); // dependencies
  


  if (!comparisonResults) {
    return (
      <div>Waiting for data...</div>
    );
  }
  



  const captureScreenAndEmail = (index,order2Data) => {
    console.log('captureScreenAndEmail called with index:', index); // Log index
    console.log('Current request statuses:', requestStatuses); // Log current statuses
    // Change the status to 'sending' for the clicked button
    const newStatuses = [...requestStatuses];
    newStatuses[index] = 'sending';
    setRequestStatuses(newStatuses);

    console.log('Updated request statuses:', newStatuses); // Log updated statuses

    // Concatenate the index with the ID to target the specific element
    const input = document.getElementById(`orderCapture-${index}`);
    
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
  
        // // Open the image in a new window
        // const screenshotWindow = window.open('', '_blank');
        // const img = new Image();
        // img.src = imgData;
        // screenshotWindow.document.body.appendChild(img);
  
        // Split the data URL at the comma and get the base64 data
        const base64Data = imgData.split(",")[1];
  
        // Send the screenshot to the backend
        sendScreenCapture(base64Data,index,order2Data);
      });
  }


  const captureScreenAndCopy = async (index, order2Data) => {
    const input = document.getElementById(`orderCapture-${index}`);
    console.log('captureScreenAndCopy-order2Data.id',order2Data);
    try {
      const canvas = await html2canvas(input);
      canvas.toBlob(async (blob) => {
        const item = new ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([item]);
        updateCopyStatus('🎉 Copied, Now Paste in Your Skype Group'); // Success message
        setTimeout(() => updateCopyStatus('📸 Copy to Skype'), 5000); // Reset the text after 5 seconds
      });
    } catch (err) {
      console.error('Failed to copy image: ', err); // Error logging
      updateCopyStatus('😢 Oops! Something Went Wrong!'); // Failure message
      setTimeout(() => updateCopyStatus('📸 Copy to Skype'), 5000); // Reset the text after 5 seconds
    }

      // If a review is now required, capture the screen and email it
      if (requireReview[index]) {
        captureScreenAndEmail(index,order2Data);
        handleReview(index);
      }
  }
  
  

  const sendScreenCapture = async (imgData,index,order2Data) => {
    console.log('sendScreenCapture called with index:', index); // Log index
    try {
      const response = await fetch('https://api.pacificpearltraining.site/emailScreenShot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          imgData, 
          orgiinalOrderId: order2Data.id
        })
      });
  
      if (response.ok) {
        console.log('Email sent successfully');
        // Change the status to 'sent' for the clicked button
        const newStatuses = [...requestStatuses];
        newStatuses[index] = 'sent';
        setRequestStatuses(newStatuses);
      } else {
        console.log('Email sending failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  

  const handleReview = (index) => {
    const newRequireReview = [...requireReview];
    newRequireReview[index] = !newRequireReview[index]; // Toggle the review state
    setRequireReview(newRequireReview);

  }




  // Function to toggle testing state
  const toggleAudioMixer = () => {
    setIsAudioMixer(!isAudioMixer);
  };
  






  return (
    
    <ShopifyProvider
    storeDomain={process.env.REACT_APP_PUBLIC_STORE_DOMAIN}
    storefrontToken={process.env.REACT_APP_PUBLIC_STOREFRONT_API_TOKEN}
    storefrontApiVersion="2023-10"
    countryIsoCode="US"
    languageIsoCode="EN"
    >
      <CartProvider
      onLineAdd={() => {
        console.log('onLineAdd - a line is being added');
      }}
      onLineAddComplete={() => {
        console.log('onLineAddComplete - a line has been added');
      }}
      >
    
        <div className="App">

          
        
          
            <Routes>
              <Route path="/" element={
                isAudioMixer ? <AudioMixer toggleAudioMixer={toggleAudioMixer} isAudioMixer={isAudioMixer}/> : (
                  
                  <>
                    <button onClick={toggleAudioMixer}>
                      {isAudioMixer ? 'Switch to Training System' : 'Switch to AudioMixer'}
                    </button>

                    <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                        {(isSocketConnected && comparisonResults.length > 0) && <OrderToAudio socket={socket} comparisonResults={comparisonResults} recivedComparisonResults={recivedComparisonResults} setRecivedComparisonResults={setRecivedComparisonResults}sessionId={sessionId}/>}
                        {comparisonResults.length > 0 ? (
                          <div className="parentContainer">
                            <h2>Compare Orders:</h2>
                            {
                            comparisonResults.map((comparisonResult, index) => {
                              const { order1Data, order2Data, score, timePenalty, unmatchedPenalty, GPTCompletionData} = comparisonResult;
                              // const buttonText = requestStatuses[index] === 'idle' ? 'Request A Review'
                              // : requestStatuses[index] === 'sending' ? 'Sending Request...'
                              // : 'Request Sent';
                              return (
                                
                                order1Data && (
                                  
                                <CoolBorder key={index} $border={requireReview[index]}>

                                  <div key={index} id={`orderCapture-${index}`}>
                                  {requireReview[index] && <ReviewRequest>Requested Review</ReviewRequest>}
                                    <div className="tableContainer" style={requireReview[index] ? {border: '1px solid red'} : {}}>
                                      {order1Data && <OrderTableStyled><OrderTable orderData={order1Data} tableTitle="Your Order" /></OrderTableStyled>}
                                      {order2Data && <OrderTableStyled><OrderTable orderData={order2Data} tableTitle="Real Order" /></OrderTableStyled>}
                                    </div>
                                    {GPTCompletionData && (
                                      <TranscriptContainer>
                                        <p style={{ color: '#007BFF' }}>Audio Transcript:</p>
                                        <p style={{ color: '#333' }}>{GPTCompletionData}</p>
                                      </TranscriptContainer>
                                    )}
                                    
                                    {score !== null && score !== undefined && (
                                      <ScoreContainer>
                                        <ScoreHeading>Score: {score}</ScoreHeading>
                                        <ScoreDetail>Time Penalty: {timePenalty}</ScoreDetail>
                                        <ScoreDetail>Unmatched Penalty: {unmatchedPenalty}</ScoreDetail>
                                      </ScoreContainer>
                                    )}
                                  </div>
                                  
                                  <StyledButton onClick={() => captureScreenAndCopy(index, order2Data)}>{copyStatusMessage}</StyledButton>
                                  <StyledButton onClick={() => handleReview(index)}>
                                    {requireReview[index] ? 'Remove Review Request' : 'Our system made a mistake? Click Here First'}
                                  </StyledButton>
                                  
                                  {/* <StyledButton onClick={() => captureScreenAndDownload(index)}>Download Screenshot</StyledButton> */}
                                  {/* <StyledButton onClick={() => captureScreenAndEmail(index)} disabled={requestStatuses[index] !== 'idle'}>
                                      {buttonText}
                                  </StyledButton> */}
                                </CoolBorder>
                              
                                )
                              );
                            })}
                            { !loading && <StyledButton onClick={loadMoreOrders} style={{ margin: '30px' }}>Load More Orders</StyledButton>}
                            {loading && <ClipLoader color={"#123abc"} loading={loading} css={override} size={150} />}
                          
                            {/* < TwilioPhone comparisonResults={comparisonResults} />   */}

                            
                          </div>
                        ) : (
                          <div>Loading...</div>
                        )}
                    </header>
                  </>
                )
              } />
              
              <Route path="/POS" element={<POSComponent customerTags={comparisonResults.length > 0 ? comparisonResults[0].customerTags : '' } customerId={comparisonResults.length > 0 ? comparisonResults[0].customerId : '' } />} />
              
              <Route path="/score-checker/:orderId" element={<ScoreChecker />} />
              <Route path="/score-checker" element={<ScoreChecker />} />
            </Routes>
        
    
        </div> 
      </CartProvider>  
    </ShopifyProvider>         
  );

}






export default App;




//pacificpearltraining.site