import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';


const StyledCell = styled.span`
    font-size: 0.8em; // adjust this value to what suits your needs
    font-weight: 500;
`;

const StyledTable = styled.table`
    
    max-width: 100%; 
    font-size: 0.5em; 
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fafafa;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.15);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;

    @media (max-width: 600px) {
        
        width: 100%;
    }
`;

const StyledTableHeader = styled.th`
    padding: 5;
    background: linear-gradient(to right, #007BFF, #0096FF);
    color: white;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    border: 1px solid #e0e0e0;
`;

const StyledTableData = styled.td`
    padding: 5;
    text-align: center;
    background-color: ${({ 'data-is-not-matching': isNotMatching }) => isNotMatching === 'true' ? '#ffcccc' : 'white'};
    border: 1px solid #e0e0e0;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.05);
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #f2f2f2;
        transform: scale(1.01);
    }
`;


const StyledTableRow = styled.tr`
    &:nth-child(even) ${StyledTableData} {
        background-color: #f2f2f2;
    }

    &:hover {
        ${StyledTableData} {
            background-color: #eaeaea;
        }
    }

    ${StyledTableData}[data-is-not-matching="true"] {
        background-color: #ffcccc;
    }
`;


const StyledTableTitle = styled.th`
    text-align: center;
    font-size: 1em;
    padding: 2;
    color: white;
    background: linear-gradient(to right, #007BFF, #0096FF);
    
    
    border-image: linear-gradient(to right, #007BFF, #0096FF) 1;
`;

function OrderTable({ orderData, tableTitle }) {
    console.log("orderData:",orderData);
  
    const data = React.useMemo(() => {
        console.log('orderData:', orderData);
        if (!orderData || !orderData.hasOwnProperty('line_items')) {
          return [];
        }
        return orderData.line_items;
      }, [orderData]);

        const orderId = orderData.id;
        const titleWithOrderId = `${tableTitle} - ${orderId}`;

        const customerPhoneNumber = orderData.shipping_address.last_name;

        let orderStartTime;
        let hasOrderStartTime;
        //find time spend placing the order
        if(orderData.metafield && orderData.metafield.value){
             orderStartTime = new Date(parseInt(orderData.metafield.value));
            console.log("orderStartTime:",orderStartTime);
            hasOrderStartTime = true;
        } else {
            console.log("Order start time not provided");
            hasOrderStartTime = false;
            orderStartTime = new Date(orderData.created_at);
        }

        const orderCreatedTime = new Date(orderData.created_at);
        
        console.log("orderCreatedTime:",orderCreatedTime);

        const timeDifferenceMs = orderCreatedTime - orderStartTime;
        const timeDifferenceSeconds = timeDifferenceMs / 1000;
        const timeDifferenceMinutes = timeDifferenceSeconds / 60;
        console.log("Time difference in minutes:", timeDifferenceMinutes);
   

  const columns = React.useMemo(
    () => [
        {
            Header: 'Quantity',
            accessor: 'quantity',
            Cell: ({ value }) => {
                return <p>{value}</p>;
            },
        },
        {
            Header: 'Translation',
            accessor: 'sku', 
            // Cell: ({ value, row }) => row.original.quantity !== 1 ? `&#8203;``oaicite:{"number":1,"invalid_reason":"Malformed citation 【${row.original.quantity}份】"}``&#8203;${value}` : value,
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ value, row }) => {
                const variantParts = row.original.variant_title ? row.original.variant_title.split(' / ') : [];
                const size = variantParts[0];
                const option = variantParts[1];
        
                // Check if properties array exists and has at least one item
                if (row.original.properties && row.original.properties.length > 0) {
                    // Check if the first property's name is "_io_parent_order_group"
                    if (row.original.properties[0].name === "_io_parent_order_group") {
                        return <StyledCell>{row.original.variant_title}</StyledCell>;  // Show variant_title
                    }
                }
        
                let title = value;
        
                if (size && size !== '#' && !size.includes('W.')) {
                    title = `(${size}) ` + title;
                }
        
                if (option && option !== '#' && option !== size) {
                    title += ` (${option})`;
                }
        
                return <StyledCell>{title}</StyledCell>;  // Default to showing title
            },
        },
        {
            Header: 'Special Requests',
            accessor: 'properties',
            Cell: ({ value }) => {
                return value.map((p, i) => {
                    if (p.name.includes('_io_')) {
                        return null;
                    }
        
                    if (p.name.includes('Side Option') && p.value !== '') {
                        return <div key={i}>{p.value};<br/></div>;
                    } else if (p.value !== '' && p.value !== 'None' && p.value !== 'Yes' && p.name !=='size(id:576434)' && p.name !== ':') {
                        if (!p.value.includes('LESS') && !p.value.includes('ON THE SIDE') && !p.value.includes('WITH') && !p.value.includes('ONLY')) {
                            if (!p.value.includes('ADD') && !p.value.includes('SPECIAL INSTRUCTIONS') && !p.name.includes('USE DIFFERENT SOURCE')) {
                                if (p.name.includes('Chinese')) {
                                    return <div class= "Chinese">【{ p.value }】</div>;
                                } else {
                                    return <div key={i}><strong>{p.value};</strong><br/></div>;
                                }
                            }
                        }
                    }
                    return null;
                });
            }
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ value, row }) => `$${(value * row.original.quantity).toFixed(2)}`,
        },
        {
        Header: 'Matching?',
        accessor: 'isNotMatching',
        Cell: ({ value }) => value ? 'No' : 'Yes',
        }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

    return (
        <StyledTable {...getTableProps()}>
            <thead>
                <tr>
                    <StyledTableTitle colSpan={columns.length}>{titleWithOrderId}</StyledTableTitle>
                </tr>
                <tr>
                    <StyledTableTitle colSpan={columns.length}>Customer Number: {customerPhoneNumber}</StyledTableTitle>
                </tr>
                <tr>
                    <StyledTableTitle colSpan={columns.length}>Total Time Spend: {timeDifferenceMinutes.toFixed(2)} minutes</StyledTableTitle>
                </tr>
                <tr>
                    <StyledTableTitle colSpan={columns.length}>
                        Order Start Time: {hasOrderStartTime ? orderStartTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: 'America/New_York' }) : 'Not available'}
                    </StyledTableTitle>
                </tr>

                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <StyledTableHeader {...column.getHeaderProps()}>{column.render('Header')}</StyledTableHeader>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <StyledTableRow {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                
                                <StyledTableData {...cell.getCellProps()} data-is-not-matching={cell.row.original.isNotMatching}>{cell.render('Cell')}</StyledTableData>



                            ))}
                        </StyledTableRow>
                    )
                })}
            </tbody>
        </StyledTable>
    );
}

export default OrderTable;
