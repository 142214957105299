import React, { useEffect, useState } from 'react';

const AudioMixer = ({toggleAudioMixer,isAudioMixer}) => {
  const [devices, setDevices] = useState([]);
  const [selectedMic, setSelectedMic] = useState('');
  const [selectedCompAudio, setSelectedCompAudio] = useState('');
  const [selectedOutput, setSelectedOutput] = useState('');
  const [audioContext, setAudioContext] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [compStream, setCompStream] = useState(null);
  

  useEffect(() => {
    // Only create a new AudioContext if one does not already exist
    if (!audioContext) {
      const newAudioContext = new AudioContext({
        sampleRate: 48000,
        latencyHint: 'playback',
      });
      setAudioContext(newAudioContext);
    }
  
    // Only create a new Audio element if one does not already exist
    if (!audioElement) {
      const newAudioElement = new Audio();
      document.body.appendChild(newAudioElement);
      setAudioElement(newAudioElement);
    }
  
    // Cleanup function
    return () => {
      // Close the AudioContext if it exists
      audioContext?.close();
  
      // Remove the Audio element from the DOM if it exists
      if (audioElement && document.body.contains(audioElement)) {
        document.body.removeChild(audioElement);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);// This useEffect is for setup and cleanup on mount and unmount, hence the empty dependency array



  useEffect(() => {
    // Enumerate all audio input devices and output devices
    navigator.mediaDevices.enumerateDevices()
      .then(deviceList => {
        console.log('Devices:', deviceList);
        const audioDevices = deviceList.filter(device => device.kind === 'audioinput' || device.kind === 'audiooutput');
        setDevices(audioDevices);

        // Set BlackHole as the default selectedCompAudio
        const blackHoleDevice = audioDevices.find(device => device.label.includes('BlackHole') && device.kind === 'audioinput');
        if (blackHoleDevice) {
          setSelectedCompAudio(blackHoleDevice.deviceId);
        }
      })
      .catch(err => {
        console.error('Error enumerating devices:', err);
      });
  }, []); // This useEffect is for device enumeration and has no dependencies


  const startMixing =  () => {
   

    // Check and clean up any existing computer audio stream
    if (compStream) {
      compStream.getTracks().forEach(track => track.stop());
      setCompStream(null); // Reset the state to ensure it's clean for the next operation
    }

    // Resume the AudioContext if it's in a suspended state
    if (audioContext && audioContext.state === 'suspended') {
      audioContext.resume();
    }


    // Disconnect any existing connections
    audioContext.destination.disconnect();

    // Create gain nodes
    const micGain = audioContext.createGain();
    const compGain = audioContext.createGain();

    // Create a compressor to manage dynamics
    const compressor = audioContext.createDynamicsCompressor();

    // Connect gains to compressor before connecting to destination
    micGain.connect(compressor);
    compGain.connect(compressor);
    compressor.connect(audioContext.destination);

    // Connect real microphone
    if (selectedMic) {
      navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: selectedMic } } })
        .then(stream => {
          console.log('selectedMic Stream:', stream);
          const micSource = audioContext.createMediaStreamSource(stream);
          micSource.connect(micGain);
          micGain.connect(audioContext.destination);

        })
        .catch(err => {
          console.error('Error accessing the real microphone:', err);
        });
    }

    // Connect computer audio (Blackhole)
    if (selectedCompAudio) {
      navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: selectedCompAudio } } })
        .then(stream => {
          console.log('selectedCompAudio Stream:', stream);
          setCompStream(stream); // Assuming setCompStream is a state setter for storing the stream
          const compSource = audioContext.createMediaStreamSource(stream); // Use audioContext
          compSource.connect(compGain);
          // No need to connect compGain to audioContext.destination here if it's already connected through the compressor
        })
        .catch(err => {
          console.error('Error accessing the Blackhole audio:', err);
        });
    }

    // Change audio output device
    if (audioElement) { // Ensure audioElement is checked for existence
      if (selectedOutput && typeof audioElement.setSinkId === 'function') {
        audioElement.setSinkId(selectedOutput)
          .then(() => {
            console.log('Audio output device successfully changed');
          })
          .catch(err => {
            console.error('Error changing audio output device:', err);
          });
      } else if (!selectedOutput) {
        console.log('No output device selected');
      } else {
        console.warn('Browser does not support changing audio output device');
      }
    } else {
      console.error("Audio element is not initialized.");
    }
  };




  return (
    <div>
      <button onClick={toggleAudioMixer}>
        {isAudioMixer ? 'Switch to Training System' : 'Switch to AudioMixer'}
      </button>
      <h1>Audio Mixer</h1>
      <div>
        <label>Select Microphone: </label>
        <select onChange={(e) => setSelectedMic(e.target.value)}>
          <option value="">No Microphone</option>
          {devices.filter(device => device.kind === 'audioinput').map(device => (
            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Select Computer Audio (Blackhole): </label>
        <select value={selectedCompAudio} onChange={(e) => setSelectedCompAudio(e.target.value)}>
          {devices.filter(device => device.kind === 'audioinput').map(device => (
            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Select Output Device: </label>
        <select onChange={(e) => setSelectedOutput(e.target.value)}>
          <option value="">No Output Device</option>
          {devices.filter(device => device.kind === 'audiooutput').map(device => (
            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
          ))}
        </select>
      </div>
      <button onClick={startMixing}>Start Mixing</button>
    </div>
  );
};

export default AudioMixer;
