import React from 'react';
import {useCart} from '@shopify/hydrogen-react';




const ConvertOrderButton = ({ draftOrderId, handleDraftOrderIdChange,updatedLastName,clearLastName, customerId, lastNameButtonClickTimestamp }) => {

  const cart = useCart();
  
  const { linesRemove } = cart;

  const convertDraftToOrder = async () => {


    try {
      const response = await fetch("https://nb1.vasis.website/convertDraftToOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ draftOrderId, updatedLastName, customerId, lastNameButtonClickTimestamp }),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('response.ok, result',result);

        // Get the IDs of all lines in the cart
        const lineIds = cart.lines.map(line => line.id);

        // Remove all lines from the cart
        linesRemove(lineIds);

        clearLastName();

        handleDraftOrderIdChange('');

        // Clear the local storage values for lastName and customerId
        // localStorage.removeItem('lastName');
        // localStorage.removeItem('customerId');
        // localStorage.removeItem('lastNameButtonClickTimestamp');
        // localStorage.removeItem('pastOrderNotes');

        // try {
        //   await clearCart();  // Clear the cart after successfully converting the order
        // } catch (error) {
        //   console.error('Error clearing cart: ', error);
        //   showError("Error clearing cart: " + error.message);
        // }

        // try {
        //   await clearNote();
        // } catch (error) {
        //   console.error('Error clearing note: ', error);
        //   showError("Error clearing note: " + error.message);
        // }

      } else {
        // Handle the error case
        console.error("Error converting draft order to order");
        // showError("Error converting order: " + error.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      {updatedLastName && draftOrderId && <button onClick={convertDraftToOrder}>Convert Draft to Order</button>}
    </div>
  );
};

export default ConvertOrderButton;