import React, { useState,useEffect } from 'react';
import { ApolloClient, InMemoryCache, useQuery, gql } from '@apollo/client';
import styled from 'styled-components';

import {useCart} from '@shopify/hydrogen-react';




import OptionsSearchResults from './searchOptionResults';

const Modal = styled.div`
//   position: fixed;
//   z-index: 1;
//   right: 0;
//   top: 0;
//   width: 50%;  // Adjust this to control the width of the modal
//   height: 100%;  // Adjust this to control the height of the modal
//   overflow: auto;
//   background-color: rgba(0,0,0,0);
`;

const ModalContent = styled.div`
  font-size: 13.5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  width: 60%;
  line-height: 1.6;
  text-decoration: none;
  color: #333;
  a{
    text-decoration: none;
    color: #333;
    font-size: 15px;
    padding: 8px;
  }
`;


const ButtonVariantTitle = styled.strong`
  text-transform: uppercase;
`;

const ItemOptionsContainer = styled.div`
  
  font-size: 13.5px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  gap: 10px;
  align-items: start;
  color: #333 !important;
  text-decoration: none !important;
`;


const ItemQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: black;
  padding: 0 5px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

`;



const ArticleItem = styled.article`
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.9);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  }

  p, div {
    margin: 5px; // Increased margin for more space around elements inside ArticleItem
  }
`;

const QuantityInput = styled.input`
  text-align: center;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: black;
  padding: 0 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.15);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
`;

const QuantityButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0;
  border: none;
  color: black;
  width: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 20px;
  margin: 5px;
  font-size: 20px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const AddToCartButton = styled.button`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: black;
  padding: 0 5px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  min-width: 155px;
  min-height: 30px;
  margin: 1px 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.15);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  }

  &[disabled] {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
`;


const SearchBox = styled.input`
  min-width: 200px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 20%;
  height: 30px;
  padding: 0px 5px;
  border: none;
  border-radius: 15px;
  font-size: 14px;
  background: #f5f5f5;
  transition: width 0.4s ease-in-out, background-color 0.4s ease-in-out;

  &:focus {
    width: 25%;
    outline: none;
    background: #e0e0e0;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  @media (max-width: 600px) {
    width: 100%;

    &:focus {
      width: 100%;
    }
  }
  &::placeholder {
    text-align: center;
  }
`;

const VariantContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); // Added shadow for differentiation from background
`;

const Label = styled.label`
  display: inline-flex; // Change to inline-flex to allow elements to be in the same line
  align-items: center;
  margin-right: 2px; // Adjust margin as needed
  padding: 2px; // Adjust padding as needed
  font-size: 0.8em; // Adjust font size as needed
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 10px; // Adjust size as needed
  height: 10px; // Adjust size as needed
  border: 1px solid #999;
  border-radius: 50%;
  outline: none;
  margin-right: 2px; // Adjust margin as needed

  &:checked {
    border: 4px solid #3498db;
  }
`;



const ClearButton = styled.button`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
`;

const SearchBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

// Initialize Apollo Client
const client = new ApolloClient({
    uri: 'https://vasispos.myshopify.com/api/2023-07/graphql.json',
    headers: {
      'X-Shopify-Storefront-Access-Token': 'fdce90b66037af30f00eb1885dfb35b7'
    },
    cache: new InMemoryCache()
});
  
const SEARCH_PRODUCTS = gql`
  query predictiveSearch($query: String!) {
    predictiveSearch(query: $query, searchableFields: [VENDOR, TAG, TITLE], types: [PRODUCT], unavailableProducts: SHOW) {
      products {
        id
        tags  
        handle
        title
        vendor
        variants(first: 50) { 
          edges {
            node {
              title
              id
            }
          }
        }
      }
    }
  }
`;
  




function PredictiveSearch({ vendorFilter, searchTerm, handleSuccessAddToCart }) {
  
  const cart = useCart();
  


  const { status, linesAdd, lines ,checkoutUrl, error: cartError} = cart;



  useEffect(() => {
    console.log('useCart status', status);
  }, [status]);



  useEffect(() => {
    console.log('lines changed:', lines);
  }, [lines]); // Dependency array with 'lines'
  
  useEffect(() => {
    console.log('checkoutUrl changed:', checkoutUrl);
  }, [checkoutUrl]); // Dependency array with 'lines'




  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [products, setProducts] = useState([]);
  
  const [quantities, setQuantities] = useState({});

  const [optionSearchTerms, setOptionSearchTerms] = useState({});


  const [selectedOptions, setSelectedOptions] = useState({});

  const [searchTermWithVendorAddsSlectedOptions,setSearchTermWithVendorAddsSlectedOptions] = useState({});
  
  const [selectedOptionAttributes, setSelectedOptionAttributes] = useState({});

  const { data, loading, error } = useQuery(SEARCH_PRODUCTS, {
    variables: { query: debouncedSearchTerm },
    client: client
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 5);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (data && data.predictiveSearch && data.predictiveSearch.products) {
      let products = data.predictiveSearch.products;

      // Exclude products with tags containing specific substrings, ignoring case
      products = products.filter(product => 
        !product.tags.some(tag => ["option", "less", "no", "extra", "add",].some(excludedTag => tag.toLowerCase() === excludedTag.toLowerCase()))
      );

      // Filter out the variants' titles that do not include # only if variants exist
      products = products.map(product => {
          if (product.variants && product.variants.edges && product.variants.edges.length >= 5) {
          return {
              ...product,
              variants: {
              ...product.variants,
              edges: product.variants.edges.filter(variant => 
                  variant.node.title.toLowerCase().includes('#') ||
                  variant.node.title.toLowerCase().includes('default') ||
                  variant.node.title.toLowerCase().includes('small') ||
                  variant.node.title.toLowerCase().includes('large') ||
                  (variant.node.title.toLowerCase().includes('piece') && variant.node.title.toLowerCase().includes('plain') && !variant.node.title.toLowerCase().includes('rice') && !variant.node.title.toLowerCase().includes('lo mein')) ||
                  variant.node.title.toLowerCase() === 'plain' ||
                  variant.node.title.toLowerCase() === 'pint' ||
                  variant.node.title.toLowerCase() === 'cup' ||
                  variant.node.title.toLowerCase() === 'pt' ||
                  variant.node.title.toLowerCase() === 'quart'
              )
              }
          };
          } else {
          return product;
          }
      });

      // Sort products so that products containing "On the Side" come first, ignoring case
      products.sort((a, b) => (a.title.toLowerCase().includes("on the side") ? -1 : b.title.toLowerCase().includes("on the side") ? 1 : 0));

      setProducts(products);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  function handleQuantityChange(event, variantId) {
      setQuantities({
          ...quantities,
          [variantId]: event.target.value
      });
  }
  
  function handleIncrement(variantId) {
      setQuantities({
          ...quantities,
          [variantId]: (quantities[variantId] || 1) + 1
      });
  }
  
  function handleDecrement(variantId) {
      setQuantities({
          ...quantities,
          [variantId]: quantities[variantId] > 1 ? quantities[variantId] - 1 : 1
      });
  }
  


   


  const handleOptionSelected = (productVariantId, optionVariantTitle, optionVariantId, optionProductTitle) => {
    console.log('handleOptionSelected called with:', { productVariantId, optionVariantTitle, optionVariantId, optionProductTitle });
    setSelectedOptionAttributes(prevState => {
      const existingOptions = prevState[productVariantId] || [];
      const newOption = { optionVariantTitle, optionVariantId, optionProductTitle };
  
      // Check if the option is already selected
      const isOptionSelected = existingOptions.some(option => option.optionVariantId === newOption.optionVariantId);
  
      if (isOptionSelected) {
        // Option is already selected, remove it from the array
        return {
          ...prevState,
          [productVariantId]: existingOptions.filter(option => option.optionVariantId !== newOption.optionVariantId)
        };
      } else {
        // Option is not selected, add it to the array
        return {
          ...prevState,
          [productVariantId]: [...existingOptions, newOption]
        };
      }
    });
  };


  function handleAddToCart(variantId) {
    const quantity = quantities[variantId] || 1; // Get the quantity from the state
    const selectedOption = selectedOptionAttributes[variantId];
    let mainLineAttributes = [];
    let lines = [];
    
    
    
    
    // If selected options are available, convert them to an array of key-value objects
    if (selectedOption) {

      // Generate a random 13-digit number
      const randomNum = Math.floor(Math.random() * (9e12)) + 1e12;

      mainLineAttributes.push({
        key: "_io_order_group",
        value: randomNum.toString() // Convert the number to a string
      });


      selectedOption.forEach(option => {
        mainLineAttributes.push({
          key: option.optionVariantTitle,
          value: option.optionVariantId
        });

        const line = {
          merchandiseId: option.optionVariantId,
          quantity: 1, // Set quantity to 1 or any other value as needed
          attributes: [
            {
              key: "_io_parent_order_group",
              value: randomNum.toString() // Convert the number to a string
            }
          ]
        };
        lines.push(line);

      });

    }


    
    const mainLine = { 
      merchandiseId: variantId, 
      quantity: quantity,
      attributes: mainLineAttributes, // Use the key-value object as attributes
    };

    lines.push(mainLine);



    linesAdd(lines); // Pass an array of lines to linesAdd
    
    if (cartError) {
      console.log('cartError',cartError);
      return <p>Error adding to cart: {cartError.message}</p>;
    }
    handleSuccessAddToCart('');
  }

 

  function handleOptionSearchInputChange(event, variantId) {
    setOptionSearchTerms({
      ...optionSearchTerms,
      [variantId]: event.target.value
    });
  
    if (event.target.value) {
      setSearchTermWithVendorAddsSlectedOptions({
        ...searchTermWithVendorAddsSlectedOptions,
        [variantId]: selectedOptions[variantId] ? vendorFilter + " " + selectedOptions[variantId] + " " + event.target.value : vendorFilter + " " + event.target.value 
      });
    } else {
      setSearchTermWithVendorAddsSlectedOptions({
        ...searchTermWithVendorAddsSlectedOptions,
        [variantId]: ''
      });
    }
  }

 



  function handleOptionChange(event, variantId) {
    setSelectedOptions({
        ...selectedOptions,
        [variantId]: event.target.value
    });


    setSearchTermWithVendorAddsSlectedOptions({
      ...searchTermWithVendorAddsSlectedOptions,
      [variantId]: optionSearchTerms[variantId] ? vendorFilter + " " + event.target.value + " " + optionSearchTerms[variantId] : vendorFilter + " " + event.target.value 
    });
    
    

  }






  const options = ["", "Add", "No", "LESS", "Only", "EXTRA SAUCE", "On the Side", "USE DIFFERENT SAUCE", "SPECIAL INSTRUCTION"];

  return (

    <Modal>
      <ModalContent>
        {products && products.map(product => {
          
          return (
            <ArticleItem key={product.handle}>
              <p><a href={`https://vasispos.myshopify.com/products/${product.handle}`} target="_blank" rel="noopener noreferrer">{product.title} - {product.vendor}</a></p>

              <ItemOptionsContainer>
                {product.variants.edges.map(variant => {
                  const productVariantId = variant.node.id; // Extract the numeric part of the ID
                  let variantTitle = variant.node.title;
                  if (variantTitle === "Default Title") {
                    variantTitle = "";
                  }
                  let addToCartText = variantTitle ? <ButtonVariantTitle>{variantTitle}</ButtonVariantTitle> : "Add to Cart";

                  return (
                    <VariantContainer key={productVariantId}>
                      {/* Display selected options for each variant */}
                      {Array.isArray(selectedOptionAttributes[productVariantId]) && selectedOptionAttributes[productVariantId].map(option => (
                        <div key={option.optionVariantId} style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ margin: '0' }}>{option.optionVariantTitle}</p>
                          <button 
                            onClick={() => handleOptionSelected(productVariantId, option.optionVariantTitle, option.optionVariantId, product.title)}
                            style={{ backgroundColor: 'pink', border: '1px solid red', marginLeft: '10px' }}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <ItemQuantity>
                        <QuantityButton data-action="decrement" onClick={() => handleDecrement(productVariantId)}>-</QuantityButton>
                        <QuantityInput type="number" min="1" value={quantities[productVariantId] || 1} onChange={(event) => handleQuantityChange(event, productVariantId)} />
                        <QuantityButton data-action="increment" onClick={() => handleIncrement(productVariantId)}>+</QuantityButton>
                        <AddToCartButton data-id={productVariantId} onClick={() => handleAddToCart(productVariantId)}>{addToCartText}</AddToCartButton>
                      </ItemQuantity>
                        <div onChange={(event) => handleOptionChange(event, productVariantId)}>
                          {options.map(option => (
                            <Label key={option}>
                              <RadioButton value={option} name={productVariantId} checked={selectedOptions[productVariantId] === option} onChange={(event) => handleOptionChange(event, productVariantId)} /> 
                              {option || 'Include All Options'}
                            </Label>
                          ))}
                        </div>
                        <SearchBoxWrapper>
                          <SearchBox 
                            type="text" 
                            value={optionSearchTerms[productVariantId] || ''} 
                            onChange={(event) => handleOptionSearchInputChange(event, productVariantId)} 
                            placeholder="Search Options..." 
                            autoComplete="off"
                          />
                          {optionSearchTerms[productVariantId] && (
                            <ClearButton onClick={() => handleOptionSearchInputChange({ target: { value: '' } }, productVariantId)}>
                              X
                            </ClearButton>
                          )}
                        </SearchBoxWrapper>
                      {searchTermWithVendorAddsSlectedOptions[productVariantId] !== undefined && searchTermWithVendorAddsSlectedOptions[productVariantId].length > 0 && <OptionsSearchResults productVariantId={productVariantId}  searchTermWithVendorAddsSlectedOptions={searchTermWithVendorAddsSlectedOptions[productVariantId]} optionSearchTerms={optionSearchTerms[productVariantId] || ''} handleOptionSelected={handleOptionSelected} selectedOptionAttributes={selectedOptionAttributes} client={client} />}
                    </VariantContainer>
                  );
                })}
              </ItemOptionsContainer>
              
            </ArticleItem>
          );
        })}
      </ModalContent>
    </Modal>
  
  );
}



export default PredictiveSearch;