import React, { useRef,useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Table, Typography } from 'antd';
const { Text, Title } = Typography;

const ScoreChecker = () => {
  const { orderId: orderIdFromUrl } = useParams();
  const [orderId, setOrderId] = useState(orderIdFromUrl || '');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, sethasNextPage] = useState(null);
  const [overallPercentage, setOverallPercentage] = useState(null);
  const fixedDivRef = useRef(null);
  const [fixedDivHeight, setFixedDivHeight] = useState(0);

  useEffect(() => {
    if (fixedDivRef.current) {
      setFixedDivHeight(fixedDivRef.current.offsetHeight);
    }
  }, [data]);

  const handleInputChange = (event) => {
    setOrderId(event.target.value);
  };

  const groupOrdersByDate = (orders) => {
    const groupedByDate = {};
  
    orders.forEach((order) => {
      // Assuming `order.node.createdAt` is a string in ISO format or similar
      const dateStr = new Date(order.node.createdAt).toLocaleDateString();
  
      // Extract the numeric part of the orderId
      const numericOrderId = order.node.id.match(/\d+$/)[0];

      let score = null;
      let otherTags = [];
  
      // Define the score pattern
      const scoreRegex = /^\d{1,4}\.\d{1,2}$/;
  
      // Separate score tag from other tags
      order.node.tags.forEach((tag) => {
        if (scoreRegex.test(tag)) {
          score = tag; // Found the score
        } else {
          otherTags.push(tag); // Collect other tags
        }
      });
  
      if (!groupedByDate[dateStr]) {
        groupedByDate[dateStr] = {
          orders: [],
          percentageAbove90: 0
        };
      }
  
      // Push the order with score and other tags to the group
      groupedByDate[dateStr].orders.push({
        ...order,
        node: {
          ...order.node,
          id: numericOrderId,
          score, // Add the score
          otherTags, // Add the remaining tags
          createdAt: new Date(order.node.createdAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'EST' })
        },
      });

      // Calculate percentage of orders above 90 for each date
      for (let date in groupedByDate) {
        const orders = groupedByDate[date].orders;
        const above90Count = orders.filter(order => parseFloat(order.node.score) > 90).length;
        const percentageAbove90 = (above90Count / orders.length) * 100;
        groupedByDate[date].percentageAbove90 = percentageAbove90;
      }
      
    });
  
    return groupedByDate;
  };
  

  const handleSubmit = async (event) => {
    console.log('handleSubmit called');
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.pacificpearltraining.site/scorechecker?orderId=${orderId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok, response:',response);
      }
      const result = await response.json();
      const grouped = groupOrdersByDate(result.orders); // Process data to group by date
      setData(grouped); // Update state with grouped data
      setEndCursor(result.endCursor);
      sethasNextPage(result.hasNextPage)

    } catch (error) {
      console.error('scoreChecker - Failed to fetch data at handleSubmit:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.pacificpearltraining.site/scorechecker?orderId=${orderId}&cursor=${endCursor}`);
      if (!response.ok) {
        throw new Error('Network response was not ok, response:',response);
      }
      const result = await response.json();
      const grouped = groupOrdersByDate(result.orders); // Process data to group by date
      setData(prevData => {
        // Merge previous data with new data
        for (let date in grouped) {
          if (prevData[date]) {
            prevData[date].orders = [...prevData[date].orders, ...grouped[date].orders];
            // Recalculate percentageAbove90
            const above90Count = prevData[date].orders.filter(order => parseFloat(order.node.score) > 90).length;
            prevData[date].percentageAbove90 = (above90Count / prevData[date].orders.length) * 100;
          } else {
            prevData[date] = grouped[date];
          }
        }
        return { ...prevData };
      });
      setEndCursor(result.endCursor);
      sethasNextPage(result.hasNextPage)
  
    } catch (error) {
      console.error('scoreChecker - Failed to fetch data at handleLoadMore:', error);
    } finally {
      setIsLoading(false);
    }
  };




  useEffect(() => {
    console.log('data:',data);
    console.log('hasNextPage:',hasNextPage);
  }, [data,hasNextPage]);
  

  const calculateOverallPercentage = () => {
    let totalOrders = 0;
    let totalAbove90 = 0;

    for (let date in data) {
      totalOrders += data[date].orders.length;
      totalAbove90 += data[date].orders.filter(order => parseFloat(order.node.score) > 90).length;
    }

    setOverallPercentage((totalAbove90 / totalOrders) * 100);
  };



  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      {data && (
        <div ref={fixedDivRef} style={{ position: 'fixed', top: 0, backgroundColor: '#f8f9fa', padding: '10px', textAlign: 'center', marginBottom: '20px', width: '100%', zIndex: 1000 }}>
          <Button onClick={calculateOverallPercentage} type="primary">Calculate Overall Percentage</Button>
          {overallPercentage !== null && <Text type="secondary">Overall: {overallPercentage.toFixed(2)}% of orders are above 90</Text>}
        </div>
      )}
      <div style={{ marginTop: fixedDivHeight + 'px' }}>
        <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
          <Input placeholder="Enter A Random Order ID For The Trainee" value={orderId} onChange={handleInputChange} style={{ margin: '0 10px' }} />
          <Button type="submit"  onClick={handleSubmit} disabled={isLoading} loading={isLoading} >
            Submit
          </Button>
        </form>
      </div>

      {data && Object.entries(data).map(([date, { orders, percentageAbove90 }]) => (
      <div key={date} style={{ marginBottom: '20px' }}>
      <Title level={5} type="secondary">{date}</Title>
      <Text type="secondary">{orders.length} orders in total, {percentageAbove90.toFixed(2)}% of orders are above 90</Text>
      <Table dataSource={orders} rowKey={record => record.node.id} pagination={false}>
        <Table.Column title="Order Id" dataIndex={['node', 'id']} key="id" />
        <Table.Column title="Score" dataIndex={['node', 'score']} key="score" render={score => score !== null ? score : 'N/A'} />
        <Table.Column title="Other Tags" dataIndex={['node', 'otherTags']} key="otherTags" render={tags => tags.join(', ')} />
        <Table.Column title="createdAt" dataIndex={['node', 'createdAt']} key="createdAt" />
      </Table>
      </div>
      ))}
      {data && hasNextPage && (
        <Button type="button" onClick={handleLoadMore} disabled={isLoading} loading={isLoading}>
          Load More
        </Button>
      )}
    </div>
  );
  


};

export default ScoreChecker;
