import React, { useState,useEffect } from 'react';
import PredictiveSearch from './shopifyPredictiveSearch';

import styled from 'styled-components';
import {useCart} from '@shopify/hydrogen-react';
import CartDetails from './CartDetails';
import TaxButton from './taxButton';

import ConvertOrderButton from './ConvertOrderButton.js';

const SearchBox = styled.input`
  margin: 1%;
  width: 20%;
  height: 30px;
  padding: 0px 5px;
  border: none;
  border-radius: 15px;
  font-size: 14px;
  background: #f5f5f5;
  transition: width 0.4s ease-in-out, background-color 0.4s ease-in-out;

  &:focus {
    width: 25%;
    outline: none;
    background: #e0e0e0;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  @media (max-width: 600px) {
    width: 100%;

    &:focus {
      width: 100%;
    }
  }
`;


const POSComponent = ({customerTags,customerId}) => {

  const [updatedLastName, setUpdatedLastName] = useState('');

  const [draftOrderId, setDraftOrderId] = useState(null);

  const [lastNameButtonClickTimestamp, setLastNameButtonClickTimestamp] = useState(null);



  const handleDraftOrderIdChange = (id) => {
    setDraftOrderId(id);
    console.log(draftOrderId);
  };



  const cart = useCart();
  
  const {id,status, lines, note, cost, error: cartError} = cart;

  useEffect(() => {
    console.log('useCart id updated', id);
  }, [id]);

  useEffect(() => {
    console.log('POSComponent - useCart status', status);
  }, [status]);


  useEffect(() => {
    console.log('POSComponent - useCart cartError', cartError);
  }, [cartError]);

  useEffect(() => {
    console.log('POSComponent - lines changed:', lines);
  }, [lines]); // Dependency array with 'lines'


  // console.log('POSComponent ran');
  const [searchTerm, setSearchTerm] = useState('');
  const [vendorFilter, setVendorFilter] = useState('');


  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleSuccessAddToCart = (value) => {
    setSearchTerm(value);
  };
  
  useEffect(() => {
    if (Array.isArray(customerTags) && customerTags.length > 0) {
      let tempVendorFilter = '';
  
      customerTags.forEach(function(tag) {
        tag = tag.trim();
        if (tag === "No1WokRestaurant") {
          tag = "No1 Wok Restaurant";
        }
        if (tag.toLowerCase() !== "trainee" && !/\d/.test(tag)) {
          if (tempVendorFilter !== "") tempVendorFilter += " OR ";
          tempVendorFilter += tag; 
        }
      });
      
      setVendorFilter(tempVendorFilter);
    }
  }, [customerTags]);


  const searchTermWithVendor = searchTerm ? vendorFilter + " " + searchTerm : '';

  const handleLastNameChange = (event) => {
    setUpdatedLastName(event.target.value);
    setLastNameButtonClickTimestamp(Date.now());
  };
  
  const clearLastName = () => {
    setUpdatedLastName("");
  };
  

  return (

      <div>
        <input 
          type="text" 
          value={updatedLastName} 
          onChange={handleLastNameChange} 
          placeholder="Enter Phone Number" 
        />
        <SearchBox type="text" value={searchTerm} onChange={handleInputChange} placeholder="Search..." autoComplete="off"/>
        {searchTermWithVendor && <PredictiveSearch vendorFilter={vendorFilter} searchTerm={searchTermWithVendor} handleSuccessAddToCart= {handleSuccessAddToCart}/>}
        {lines.length > 0 && <CartDetails lines={lines} cost={cost} />}
        {lines.length > 0 && <TaxButton customerId={customerId} lines={lines} cartNote={note} handleDraftOrderIdChange={handleDraftOrderIdChange} />}
        {draftOrderId && <ConvertOrderButton draftOrderId={draftOrderId} handleDraftOrderIdChange={handleDraftOrderIdChange} updatedLastName={updatedLastName} clearLastName={clearLastName} customerId={customerId} lastNameButtonClickTimestamp={lastNameButtonClickTimestamp} />}
      </div>

  );
};







export default POSComponent;


