import React, { useState,useEffect } from 'react';

import { gql,useQuery } from '@apollo/client';
import styled from 'styled-components';

const ArticleItem = styled.article`
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  border-radius: 10px;s
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.9);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 0;
  }
`;


const ItemOptionsContainer = styled.div`
  font-size: 13.5px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  gap: 10px;
  align-items: start;
  color: #333 !important;
  text-decoration: none !important;
`;



const SEARCH_OPTIONS = gql`
query predictiveSearch($query: String!) {
    predictiveSearch(query: $query, searchableFields: [VENDOR, TAG, VARIANTS_TITLE], types: [PRODUCT], unavailableProducts: SHOW) {
    products {
        tags  
        handle
        title
        vendor
        variants(first: 100) { 
        edges {
            node {
            title
            id
            }
        }
        }
    }
    }
}
`;

const Option = styled.div`
  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: #f0f0f0;
    color: #000;
    border: 1px solid #000;
  }
`;

function SearchResults({ productVariantId, searchTermWithVendorAddsSlectedOptions, optionSearchTerms, handleOptionSelected, selectedOptionAttributes,client }) {
    
    
    const [products, setProducts] = useState([]);
    
    console.log('searchTermWithVendorAddsSlectedOptions:',searchTermWithVendorAddsSlectedOptions);
    // useEffect(() => {
    //     console.log('optionSearchTerms changed:', optionSearchTerms);
    // }, [optionSearchTerms]);

    const { data, loading, error } = useQuery(SEARCH_OPTIONS, {
        variables: { query: searchTermWithVendorAddsSlectedOptions || '' },
        client: client
    });


    useEffect(() => {
        if (data && data.predictiveSearch && data.predictiveSearch.products) {
            let products = data.predictiveSearch.products;

            // Log products here
            console.log(products);
            // Filter products with tags containing specific substrings, ignoring case
            products = products.filter(product => 
                product.tags.some(tag => 
                    ["option", "less", "no", "extra", "add", 'only','Special Instruction','Sauce','On the Side'].some(filterTag => 
                        tag.toLowerCase().includes(filterTag.toLowerCase())
                    )
                )
            );
                // // Log products here
                // console.log(products);
            // Filter out the variants' titles that do not include optionSearchTerms
            products = products.map(product => {
                if (product.variants && product.variants.edges && product.variants.edges.length >= 5) {
                    return {
                        ...product,
                        variants: {
                            ...product.variants,
                            edges: product.variants.edges.filter(variant => {
                                const variantTitle = variant.node.title.toLowerCase().trim();
                                const searchTerms = optionSearchTerms.toLowerCase().trim();
                                const variantTitleWords = variantTitle.split(' ');
                                const searchTermsWords = searchTerms.split(' ');
            
                                return variantTitle.includes(searchTerms) || 
                                    searchTerms.includes(variantTitle) || 
                                    searchTermsWords.some(word => variantTitleWords.includes(word)) ||
                                    variantTitleWords.some(word => searchTermsWords.includes(word));
                            })
                        }
                    };
                } else {
                    return product;
                }
            });

            // Sort products so that products containing "On the Side" come first, ignoring case
            // products.sort((a, b) => (a.title.toLowerCase().includes("on the side") ? -1 : b.title.toLowerCase().includes("on the side") ? 1 : 0));

            setProducts(products);
        }
    }, [data,optionSearchTerms]);       


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;


    const handleOptionClick = (productVariantId,optionVariantTitle, optionVariantId, optionProductTitle) => {
      // Call the callback function passed in through props
      handleOptionSelected(productVariantId, optionVariantTitle, optionVariantId, optionProductTitle);
    };
  

    



    return (
        <div>
          {products && products.map(product => {
            return (
              <ArticleItem key={product.handle}>
                {/* <p><a href={`https://vasispos.myshopify.com/products/${product.handle}`} target="_blank" rel="noopener noreferrer">{product.title}</a></p> */}
                <p>{product.title}</p>
                <ItemOptionsContainer>
                  {product.variants.edges.map(variant => {
                    const optionVariantId = variant.node.id; 
                    let optionVariantTitle = variant.node.title;
                    if (optionVariantTitle === "Default Title") {
                      optionVariantTitle = "";
                    }
      
                    // Check if the option is selected
                    const isSelected = selectedOptionAttributes[productVariantId] && selectedOptionAttributes[productVariantId].some(option => option.optionVariantId === optionVariantId);
      
                    return (
                        <Option 
                        key={optionVariantId} 
                        onClick={() => handleOptionClick(productVariantId, optionVariantTitle, optionVariantId, product.title)}
                        className={isSelected ? 'selected' : ''}
                        >
                        {optionVariantTitle} 
                        </Option>
                    )
                  })}
                </ItemOptionsContainer>
              </ArticleItem>
            );
          })}
        </div>
      );
}





export default SearchResults;