import React, { useState } from 'react';

const TaxButton = ({customerId, lines, cartNote, handleDraftOrderIdChange}) => {

  const [tax, setTax] = useState(0);
  const [totalCost, setTotalCost] = useState(0);


  const fetchTotalWithTax = async () => {

    const reformattedLines = lines.map(line => {
        const variant_id = parseInt(line.merchandise.id.split('/').pop());
        const quantity = line.quantity;
        const properties = line.attributes.reduce((acc, attr) => {
          acc[attr.key] = attr.value;
          return acc;
        }, {});
      
        return { variant_id, quantity, properties };
    });




    try {
      const response = await fetch("https://api.pacificpearltraining.site/api/tax-rate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          lineItems: reformattedLines,
          note: cartNote
        }),
      });

      const data = await response.json();
      console.log(data); // You can handle the response as per your requirement
     
      // Update the state variables with the new data
      setTax(data.total_tax);
      setTotalCost(data.total_price);
      handleDraftOrderIdChange(data.draft_order_id);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <button onClick={fetchTotalWithTax}>Get Tax</button>
      {tax > 0 && <p>Tax: {tax}</p>}
      {totalCost > 0 && <p>Total Cost: {totalCost}</p>}
    </div>
  );
};

export default TaxButton;